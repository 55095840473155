

























import { Component, Vue, Prop } from 'vue-property-decorator';
import { OrderBumpPayload } from '@/sparkmembers/views/ProductSetup/components/OrderBumpConfig/types';
import { IOffer } from '@/types/offers';

@Component({
  components: {
    OrderBumpPreviewItem: () => import('./OrderBumpPreviewItem.vue'),
  },
})
export default class OrderBumpPreviewSection extends Vue {
  @Prop({ required: true }) offer: IOffer;
  @Prop({ required: true }) orderBumpList: Array<OrderBumpPayload>;

  get hasOrderBumpSelected() {
    return Boolean(this.orderBumpList.length);
  }
}
